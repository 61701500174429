<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
    onsubmit="return false;"
  >
    <div>
      <v-card flat color="white" class="pb-3">
        <loading :active.sync="loading" />
        <!-- <ImageCropper
          :toShow="cropperModal"
          :status="status"
          v-if="cropFileUrl"
          :src="cropFileUrl"
        /> -->
        <v-card-text v-if="x">
          <div class="heading primary-clr">
            <span class="ft-20">Driver Info</span>
            <v-btn
              size="large"
              v-permissions="'edit-profile-driver'"
              :color="readonly ? `orange darken-1` : `grey-btn`"
              @click.native="checkEdit()"
              style="color: white"
              :loading="x.loading"
              >{{ readonly ? `Edit Profile` : `Cancel Edit` }}</v-btn
            >
          </div>

          <v-layout wrap>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">First Name*</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  label="First Name*"
                  :rules="[
                    rules.noWhiteSpace,
                    rules.required,
                    rules.onlyEnglish,
                  ]"
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="x.firstName"
                  required
                  maxlength="45"
                />
              </div>
            </v-flex>
            <v-flex
              v-if="this.x.workingCountry == 'KSA'"
              xs12
              class="pr-4"
              sm6
              md3
            >
              <div class="subheading muted caption">First Name*(in Arabic)</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  label="First Name* (in Arabic)"
                  :rules="[
                    rules.noWhiteSpace,
                    rules.required,
                    rules.arabicLanguage,
                  ]"
                  single-line
                  class="pt-0 currencyTitle arabic"
                  v-model="x.arabicFirstName"
                  required
                />
              </div>
            </v-flex>

            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Last Name*</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  :rules="[
                    rules.noWhiteSpace,
                    rules.required,
                    rules.onlyEnglish,
                  ]"
                  label="Last Name*"
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="x.lastName"
                  required
                  maxlength="45"
                />
              </div>
            </v-flex>
            <v-flex
              v-if="this.x.workingCountry == 'KSA'"
              xs12
              class="pr-4"
              sm6
              md3
            >
              <div class="subheading muted caption">Last Name* (in Arabic)</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  :rules="[
                    rules.noWhiteSpace,
                    rules.required,
                    rules.arabicLanguage,
                  ]"
                  label="Last Name* (in Arabic)"
                  single-line
                  class="pt-0 currencyTitle arabic"
                  v-model="x.arabicLastName"
                  required
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              sm6
              md3
              v-if="this.x.workingCountry != 'PAK'"
            >
              <div class="subheading muted caption">VAT No.</div>

              <div class="heading">
                <v-text-field
                  label="VAT No."
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  :value="x.vatNumber"
                  v-model="x.vatNumber"
                  single-line
                ></v-text-field>
              </div>
            </v-flex>

            <v-flex xs12 v-if="readonly" sm6 md3>
              <a
                v-if="
                  x.profilePicURL &&
                  x.profilePicURL.substr(
                    x.profilePicURL.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.profilePicURL"
                title="Profile Picture"
                single-line
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-else
                :href="x.profilePicURL"
                title="Profile Picture"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="x.profilePicURL"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
            <v-flex xs12 v-if="!readonly" sm6 md4>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Profile Picture</button>
                <input
                  ref="fileUploadProfile"
                  type="file"
                  name="myfile"
                  accept="image/*"
                  @change="profilePicChange"
                />
              </div>
              <div v-if="x.profilePicURL" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.profilePicURL &&
                    x.profilePicURL.substr(
                      x.profilePicURL.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.profilePicURL"
                  title="Profile Picture"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-else
                  :href="x.profilePicURL"
                  title="Profile Picture"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.profilePicURL"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex xs12 md3 sm6 class="pr-4">
              <div class="subheading muted caption">Country*</div>
              <div class="heading">
                <v-text-field
                  readonly
                  label="Country*"
                  class="pt-0 currencyTitle"
                  v-model="x.workingCountry"
                  :value="x.workingCountry"
                  single-line
                />
              </div>
            </v-flex>
            <v-flex md2 sm6 pr-4>
              <div class="muted caption">Driver Credit Period*</div>
              <v-text-field
                :disabled="readonly"
                label="Driver Credit Period*"
                :value="x.driverPaymentDuration"
                v-model="x.driverPaymentDuration"
                required
                type="number"
                single-line
                suffix="days"
                class="pt-0 currencyTitle"
                :rules="[rules.required]"
              />
            </v-flex>
            <br />
            <v-flex
              xs12
              class="pr-4"
              sm6
              md3
              v-bind:style="[
                this.x.workingCountry != 'KSA'
                  ? 'padding-left:40px '
                  : 'padding:0px ',
              ]"
            >
              <div class="subheading muted caption">Choose Driver Type*</div>
              <div class="heading">
                <v-autocomplete
                  :items="UserTypes"
                  label="Choose Driver Type*"
                  v-model="x.jobType"
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  single-line
                  disabled
                  required
                  autocomplete="offfadsf"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              sm6
              md3
              v-if="this.x.workingCountry != 'PAK' && this.x.jobType == 1"
            >
              <div class="subheading muted caption">Choose Border Type*</div>
              <div class="heading">
                <v-autocomplete
                  :items="borderTypes"
                  v-model="x.borderType"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  label="Choose Border Type*"
                  :rules="[rules.required]"
                  single-line
                  autocomplete="offfadsf"
                  required
                ></v-autocomplete>
              </div>
            </v-flex>
          </v-layout>

          <div
            v-if="this.x.jobType == 1 && this.x.workingCountry == 'UAE'"
            class="heading primary-clr pt-3"
          >
            Bank Details
          </div>

          <v-layout
            wrap
            v-if="this.x.jobType == 1 && this.x.workingCountry == 'UAE'"
          >
            <v-flex xs12 md4>
              <div class="subheading muted caption">All Banks Name</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="bankInfo"
                  :disabled="readonly"
                  item-text="bankName"
                  item-value="bankName"
                  v-model="x.bankName"
                  label="All Banks Name"
                  class="pt-0 currencyTitle"
                  single-line
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 md4 pl-3>
              <div class="subheading muted caption">Account Holder Name</div>
              <v-text-field
                class="pt-0 currencyTitle"
                v-model="x.bankHolderName"
                :disabled="readonly"
                label="Account Holder Name"
                :rules="[rules.onlyEnglish]"
                single-line
              ></v-text-field>
            </v-flex>

            <!-- <v-flex xs12 md4>
              <div class="subheading muted caption">All Transporter</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="transporters"
                  v-model="transporterId"
                  label="All Transporter*"
                  multiple
                  @change="!mainError ? (mainError = null) : ''"
                  single-line
                  class="pt-0 currencyTitle"
                  required
                ></v-autocomplete>
              </div>
            </v-flex> -->
          </v-layout>

          <div v-if="this.x.jobType == 1" class="heading primary-clr pt-3">
            Registering As
          </div>
          <v-layout wrap v-if="this.x.jobType == 1">
            <v-flex xs12 md4>
              <v-layout>
                <v-checkbox
                  v-model="x.type"
                  value="Transporter"
                  :disabled="readonly"
                  @change="check(type)"
                  label="Transporter"
                ></v-checkbox>
                <v-checkbox
                  v-model="x.type"
                  label="Owner driver"
                  @change="errorFalse()"
                  :disabled="readonly"
                  value="Owner"
                ></v-checkbox>
              </v-layout>
            </v-flex>

            <v-flex xs12 md6> </v-flex>

            <v-flex xs12 md4 v-if="x.type.includes('Transporter')">
              <div class="subheading muted caption">All Transporter</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  :items="transporters"
                  v-model="x.transporterId"
                  label="All Transporter*"
                  :disabled="readonly"
                  @change="mainError ? (mainError = null) : ''"
                  multiple
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex
              md12
              xs12
              class="my-3"
              v-if="haserrors"
              style="color: red; width: 50%; border: 0px; margin: auto"
            >
              {{ mainError }}
            </v-flex>
            <v-flex
              md12
              xs12
              class="my-3"
              v-if="haserrors1"
              style="color: red; width: 50%; border: 0px; margin: auto"
            >
              {{ mainError1 }}
            </v-flex>
          </v-layout>
          <hr class="divider-rule" v-if="this.x.jobType == 1" />
          <div
            v-if="this.x.jobType == 1 && x.workingCountry != 'PAK'"
            class="heading primary-clr pt-3"
          >
            Nationality Proof
          </div>
          <div
            v-if="this.x.jobType == 1 && x.workingCountry == 'PAK'"
            class="heading primary-clr pt-3"
          >
            CNIC Proof
          </div>
          <v-layout wrap v-if="this.x.jobType == 1">
            <v-flex xs12 class="pr-4" sm6 md3>
              <div
                v-if="x.workingCountry != 'PAK'"
                class="subheading muted caption"
              >
                Nationality*
              </div>
              <div
                v-if="x.workingCountry == 'PAK'"
                class="subheading muted caption"
              >
                CNIC*
              </div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  :rules="[rules.required]"
                  :close-on-content-click="true"
                  class="pt-0 currencyTitle"
                  attach
                  :items="nationalities"
                  v-model="x.nationality"
                  :label="x.workingCountry != 'PAK' ? 'Nationality*' : 'CNIC*'"
                  :disabled="readonly"
                  single-line
                  required
                  autocomplete="offfadsf"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3 v-if="x.borderType != 'Local'">
              <div
                v-if="x.workingCountry != 'PAK'"
                class="subheading muted caption"
              >
                National ID(e.g. 784199087638641)
              </div>
              <div
                v-if="x.workingCountry == 'PAK'"
                class="subheading muted caption"
              >
                CNIC ID(e.g. 784199087638641)
              </div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  :label="
                    x.workingCountry != 'PAK'
                      ? 'National ID(e.g. 784199087638641)'
                      : 'CNIC ID(e.g. 784199087638641)'
                  "
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="x.nationalId"
                  :value="x.nationalId"
                  counter
                  maxlength="16"
                  required
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="readonly && x.borderType != 'Local'"
              sm6
              md3
            >
              <a
                v-if="
                  x.nationalIdImage &&
                  x.nationalIdImage.substr(
                    x.nationalIdImage.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.nationalIdImage"
                :title="x.workingCountry != 'PAK' ? 'National Id' : 'CNIC Id'"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-if="
                  x.nationalIdImage &&
                  x.nationalIdImage.substr(
                    x.nationalIdImage.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.nationalIdImage"
                :title="x.workingCountry != 'PAK' ? 'National Id' : 'CNIC Id'"
                target="_blank"
                class
              >
                <img
                  :src="x.nationalIdImage"
                  height="70px"
                  class="thumb-img"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="!readonly && x.borderType != 'Local'"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button v-if="x.workingCountry != 'PAK'" class="btn">
                  Upload Nationality Proof
                </button>
                <button v-if="x.workingCountry == 'PAK'" class="btn">
                  Upload CNIC Proof
                </button>
                <input
                  ref="fileNationalId"
                  type="file"
                  name="myfile"
                  @change="nationalIDChanged"
                />
              </div>
              <div v-if="x.nationalIdImage" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.nationalIdImage &&
                    x.nationalIdImage.substr(
                      x.nationalIdImage.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.nationalIdImage"
                  :title="x.workingCountry != 'PAK' ? 'National Id' : 'CNIC Id'"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.nationalIdImage &&
                    x.nationalIdImage.substr(
                      x.nationalIdImage.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.nationalIdImage"
                  :title="x.workingCountry != 'PAK' ? 'National Id' : 'CNIC Id'"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.nationalIdImage"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3" xs12 md3 v-if="x.borderType != 'Local'">
              <div
                v-if="x.workingCountry != 'PAK'"
                class="subheading muted caption"
              >
                National ID Expiry
              </div>
              <div
                v-if="x.workingCountry == 'PAK'"
                class="subheading muted caption"
              >
                CNIC ID Expiry
              </div>
              <div class="heading">
                <v-menu
                  ref="nationalIdDateBool"
                  lazy
                  v-model="nationalIdDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  :disabled="readonly"
                  min-width="290px"
                  :return-value.sync="x.nationalIdExpiryDate"
                >
                  <v-text-field
                    slot="activator"
                    :label="
                      countrySortName != 'PAK'
                        ? 'National ID Expiry'
                        : 'CNIC ID Expiry'
                    "
                    required
                    single-line
                    :disabled="readonly"
                    v-model="x.nationalIdExpiryDate"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="x.nationalIdExpiryDate"
                    class="minHeight"
                    :min="dateToday"
                    @change="
                      $refs.nationalIdDateBool.save(x.nationalIdExpiryDate)
                    "
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex
              v-if="x.workingCountry != 'PAK' && x.borderType != 'Local'"
              xs12
              class="pr-4"
              sm6
              md3
            >
              <div class="subheading muted caption">Passport Number*</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  class="pt-0 currencyTitle"
                  single-line
                  label="Passport Number*"
                  v-model="x.passPortNumber"
                  :value="x.passPortNumber"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="
                readonly && x.workingCountry != 'PAK' && x.borderType != 'Local'
              "
              sm6
              md3
            >
              <a
                v-if="
                  x.passPortImage &&
                  x.passPortImage.substr(
                    x.passPortImage.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.passPortImage"
                title="Passport Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-if="
                  x.passPortImage &&
                  x.passPortImage.substr(
                    x.passPortImage.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.passPortImage"
                title="Passport Image"
                target="_blank"
                class
              >
                <img
                  :src="x.passPortImage"
                  height="70px"
                  class="thumb-img"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="
                !readonly &&
                x.workingCountry != 'PAK' &&
                x.borderType != 'Local'
              "
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Passport Picture</button>
                <input
                  ref="filePassport"
                  type="file"
                  name="myfile"
                  @change="passportChanged"
                />
              </div>
              <div v-if="x.passPortImage" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.passPortImage &&
                    x.passPortImage.substr(
                      x.passPortImage.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.passPortImage"
                  title="Passport Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.passPortImage &&
                    x.passPortImage.substr(
                      x.passPortImage.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.passPortImage"
                  title="Passport Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.passPortImage"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex
              v-if="x.workingCountry != 'PAK' && x.borderType != 'Local'"
              xs12
              class="pr-4"
              sm6
              md3
            ></v-flex>
            <v-flex
              v-if="x.workingCountry != 'PAK' && x.borderType != 'Local'"
              class="pr-3"
              xs12
              md3
            >
              <div class="subheading muted caption">Passport Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="passportDateBool"
                  lazy
                  v-model="passportDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :disabled="readonly"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="x.passportExpiryDate"
                >
                  <v-text-field
                    label="Passport Expiry*

                    "
                    slot="activator"
                    class="pt-0 currencyTitle"
                    required
                    single-line
                    :disabled="readonly"
                    :rules="[rules.required]"
                    v-model="x.passportExpiryDate"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="x.passportExpiryDate"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.passportDateBool.save(x.passportExpiryDate)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex v-if="x.workingCountry != 'PAK'" xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Emirates Id*</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  label="Emirates Id*"
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  v-model="x.emiratesID"
                  single-line
                  :value="x.emiratesID"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="readonly && x.workingCountry != 'PAK'"
              sm6
              md3
            >
              <a
                v-if="
                  x.emiratesIDImageFront &&
                  x.emiratesIDImageFront.substr(
                    x.emiratesIDImageFront.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.emiratesIDImageFront"
                title="Emirates Id image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>

              <a
                v-if="
                  x.emiratesIDImageFront &&
                  x.emiratesIDImageFront.substr(
                    x.emiratesIDImageFront.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.emiratesIDImageFront"
                title="Emirates Id image"
                target="_blank"
                class
              >
                <img
                  :src="x.emiratesIDImageFront"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="!readonly && x.workingCountry != 'PAK'"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Emirates Id</button>
                <input
                  ref="fileEmirates"
                  type="file"
                  name="myfile"
                  @change="emiratesIDChanged"
                />
              </div>
              <div v-if="x.emiratesIDImageFront" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.emiratesIDImageFront &&
                    x.emiratesIDImageFront.substr(
                      x.emiratesIDImageFront.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.emiratesIDImageFront"
                  title="Emirates Id image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.emiratesIDImageFront &&
                    x.emiratesIDImageFront.substr(
                      x.emiratesIDImageFront.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.emiratesIDImageFront"
                  title="Emirates Id image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.emiratesIDImageFront"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="readonly && x.workingCountry != 'PAK'"
              sm6
              md3
            >
              <a
                v-if="
                  x.emiratesIDImageBack &&
                  x.emiratesIDImageBack.substr(
                    x.emiratesIDImageBack.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.emiratesIDImageBack"
                title="Emirates Id Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>

              <a
                v-if="
                  x.emiratesIDImageBack &&
                  x.emiratesIDImageBack.substr(
                    x.emiratesIDImageBack.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.emiratesIDImageBack"
                title="Emirates Id Image"
                target="_blank"
                class
              >
                <img
                  :src="x.emiratesIDImageBack"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="!readonly && x.workingCountry != 'PAK'"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Emirates Id</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileEmiratesB"
                  @change="emiratesIDChangedBack"
                />
              </div>
              <div v-if="x.emiratesIDImageBack" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.emiratesIDImageBack &&
                    x.emiratesIDImageBack.substr(
                      x.emiratesIDImageBack.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.emiratesIDImageBack"
                  title="Emirates Id Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.emiratesIDImageBack &&
                    x.emiratesIDImageBack.substr(
                      x.emiratesIDImageBack.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.emiratesIDImageBack"
                  title="Emirates Id image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.emiratesIDImageBack"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex v-if="x.workingCountry != 'PAK'" class="pr-3" xs12 md3>
              <div class="subheading muted caption">Emirates Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="emiratesDateBool"
                  lazy
                  v-model="emiratesDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :disabled="readonly"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="x.emiratesIDExpiryDate"
                >
                  <v-text-field
                    slot="activator"
                    :label="x.showDate || 'Emirates ID Expiry*'"
                    required
                    single-line
                    :disabled="readonly"
                    class="pt-0 currencyTitle"
                    :rules="[rules.required]"
                    v-model="x.emiratesIDExpiryDate"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="x.emiratesIDExpiryDate"
                    :min="dateToday"
                    class="minHeight"
                    @change="
                      $refs.emiratesDateBool.save(x.emiratesIDExpiryDate)
                    "
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Preferred Location*</div>
              <div class="heading">
                <v-autocomplete
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  :items="preferredLocations"
                  :rules="[rules.required]"
                  multiple
                  v-model="x.preferredLocation"
                  label="Preferred Location*"
                  single-line
                />
              </div>
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />
          <div class="heading primary-clr pt-3">Contact & Address Details</div>
          <v-layout wrap>
            <v-flex xs12 class="pr-4" sm6 md2>
              <div class="subheading muted caption">Country Code*</div>
              <div class="heading">
                <v-autocomplete
                  class="pt-0 currencyTitle"
                  style="background-color: white !important"
                  :rules="[rules.required]"
                  :close-on-content-click="true"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  attach
                  autocomplete="offfadsf"
                  :items="countryCodes"
                  label="Country Code*"
                  single-line
                  v-model="x.countryCode"
                  :disabled="readonly"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Primary Phone Number*</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  label="Primary Phone Number*"
                  class="pt-0 currencyTitle"
                  :rules="[rules.phoneNumber, rules.required]"
                  v-model="x.phoneNo"
                  single-line
                  required
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex md6></v-flex>
            <v-flex xs12 class="pr-4" sm6 md2>
              <div
                v-if="x.borderType == 'Local'"
                class="subheading muted caption"
              >
                Country Code
              </div>
              <div
                v-if="x.borderType != 'Local'"
                class="subheading muted caption"
              >
                Country Code*
              </div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  class="pt-0 currencyTitle"
                  :disabled="readonly"
                  :label="
                    x.borderType == 'Local' ? 'Country Code' : 'Country Code*'
                  "
                  :close-on-content-click="true"
                  attach
                  item-text="dialing_code"
                  item-value="dialing_code"
                  :items="countryCodes"
                  v-model="x.secondaryCountryCode"
                  autocomplete="offfadsf"
                  single-line
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div
                v-if="x.borderType != 'Local'"
                class="subheading muted caption"
              >
                Secondary Phone Number*
              </div>
              <div
                v-if="x.borderType == 'Local'"
                class="subheading muted caption"
              >
                Secondary Phone Number
              </div>
              <div class="heading">
                <v-text-field
                  class="pt-0 currencyTitle"
                  :label="
                    x.borderType == 'Local'
                      ? 'Secondary Phone Number'
                      : 'Secondary Phone Number*'
                  "
                  :disabled="readonly"
                  v-model="x.secondaryPhoneNo"
                  single-line
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex md6></v-flex>
            <v-flex xs12 class="pr-4" sm6 md2>
              <div class="subheading muted caption">Country Code</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  :disabled="readonly"
                  label="Country Code"
                  class="pt-0 currencyTitle"
                  :close-on-content-click="true"
                  attach
                  single-line
                  :items="countryCodes"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  v-model="x.thirdCountryCode"
                  autocomplete="offfadsf"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Optional Phone Number</div>
              <div class="heading">
                <v-text-field
                  label="Optional Phone Number"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  single-line
                  v-model="x.thirdPhoneNo"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex md6></v-flex>
            <v-flex xs12 class="pr-4" sm12 md8>
              <div class="subheading muted caption">Address</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="Address"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  v-model="x.address"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm12 md4></v-flex>
            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">City</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="City"
                  :id="`autocomplete1`"
                  placeholder=" Choose City"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  v-model="x.city"
                  @input="updateCity()"
                  required
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md4 v-if="x.workingCountry != 'PAK'">
              <div class="subheading muted caption">Postal Code</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="Postal Code"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  v-model="x.postCode"
                ></v-text-field>
              </div>
            </v-flex>
          </v-layout>
          <hr
            class="divider-rule"
            v-if="this.x.jobType == 2 || this.x.jobType == 3"
          />
          <div
            v-if="this.x.jobType == 2 || this.x.jobType == 3"
            class="heading primary-clr pt-3"
          >
            Driver's License & Vehicle Information
          </div>

          <v-layout row wrap v-if="this.x.jobType == 2 || this.x.jobType == 3">
            <v-flex v-if="this.x.jobType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Flight Name*</div>
              <div class="heading">
                <v-text-field
                  class="pt-0 currencyTitle"
                  v-model="x.FlightName"
                  label="Flight Name*"
                  :rules="[rules.required]"
                  :disabled="readonly"
                  single-line
                  :value="x.FlightName"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.x.jobType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Flight No.*</div>
              <div class="heading">
                <v-text-field
                  label="Flight No.*"
                  v-model="x.WesselNo"
                  class="pt-0 currencyTitle"
                  :disabled="readonly"
                  single-line
                  :rules="[rules.required]"
                  :value="x.WesselNo"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.x.jobType == 3" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Container No.*</div>
              <div class="heading">
                <v-text-field
                  label="Container No.*"
                  class="pt-0 currencyTitle"
                  v-model="x.WesselNoSea"
                  :rules="[rules.required]"
                  :value="x.WesselNoSea"
                  single-line
                  :disabled="readonly"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.x.jobType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Other AIR CARGO flight details*
              </div>
              <div class="heading">
                <v-text-field
                  label="Other AIR CARGO flight details*"
                  class="pt-0 currencyTitle"
                  v-model="x.AirCargoDetails"
                  :value="x.AirCargoDetails"
                  :disabled="readonly"
                  single-line
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.x.jobType == 3" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Other Sea CARGO details*
              </div>
              <div class="heading">
                <v-text-field
                  class="pt-0 currencyTitle"
                  label="Other SEA CARGO details*"
                  v-model="x.SeaCargoDetails"
                  :value="x.SeaCargoDetails"
                  :disabled="readonly"
                  single-line
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.x.jobType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Tracking link or details*
              </div>
              <div class="heading">
                <v-text-field
                  class="pt-0 currencyTitle"
                  label="Tracking link or details*"
                  v-model="x.TrackingLinkAir"
                  :value="x.TrackingLinkAir"
                  :disabled="readonly"
                  single-line
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.x.jobType == 3" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Tracking link or details*
              </div>
              <div class="heading">
                <v-text-field
                  class="pt-0 currencyTitle"
                  label="Tracking link or details*"
                  v-model="x.TrackingLinkSea"
                  :value="x.TrackingLinkSea"
                  :disabled="readonly"
                  single-line
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
          </v-layout>

          <div class="heading primary-clr pt-3" v-if="this.x.jobType == 1">
            Driver's License & Vehicle Information
          </div>
          <v-layout wrap v-if="this.x.jobType == 1">
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Driver License No.*</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  label="Driving License No.*"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  class="pt-0 currencyTitle"
                  v-model="x.licenseNumber"
                  single-line
                  :value="x.licenseNumber"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="readonly" sm6 md3>
              <a
                v-if="
                  x.licenseImage &&
                  x.licenseImage.substr(x.licenseImage.lastIndexOf('.') + 1) ===
                    'pdf'
                "
                :href="x.licenseImage"
                title="License Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-if="
                  x.licenseImage &&
                  x.licenseImage.substr(x.licenseImage.lastIndexOf('.') + 1) !==
                    'pdf'
                "
                :href="x.licenseImage"
                title="License Image"
                target="_blank"
                class
              >
                <img
                  :src="x.licenseImage"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="!readonly" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Driving License</button>
                <input
                  ref="fileLicense"
                  type="file"
                  name="myfile"
                  @change="licenseChanged"
                />
              </div>
              <div v-if="x.licenseImage" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.licenseImage &&
                    x.licenseImage.substr(
                      x.licenseImage.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.licenseImage"
                  title="License Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.licenseImage &&
                    x.licenseImage.substr(
                      x.licenseImage.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :rules="[rules.required]"
                  :href="x.licenseImage"
                  title="License Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.licenseImage"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="readonly" sm6 md3>
              <a
                v-if="
                  x.licenseImageBack &&
                  x.licenseImageBack.substr(
                    x.licenseImageBack.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.licenseImageBack"
                title="License Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-if="
                  x.licenseImageBack &&
                  x.licenseImageBack.substr(
                    x.licenseImageBack.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.licenseImageBack"
                title="License Image"
                target="_blank"
                class
              >
                <img
                  :src="x.licenseImageBack"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="!readonly" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Driving License</button>
                <input
                  ref="fileLicenseB"
                  type="file"
                  name="myfile"
                  @change="licenseChangedBack"
                />
              </div>
              <div v-if="x.licenseImageBack" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.licenseImageBack &&
                    x.licenseImageBack.substr(
                      x.licenseImageBack.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.licenseImageBack"
                  title="License Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.licenseImageBack &&
                    x.licenseImageBack.substr(
                      x.licenseImageBack.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.licenseImageBack"
                  title="License Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.licenseImageBack"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3" xs12 md3>
              <div class="subheading muted caption">License Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="licenseDateBool"
                  lazy
                  v-model="licenseDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :disabled="readonly"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="x.licenseExpiryDate"
                >
                  <v-text-field
                    slot="activator"
                    required
                    class="pt-0 currencyTitle"
                    :label="x.showDate || 'License Expiry*'"
                    :rules="[rules.required]"
                    single-line
                    :disabled="readonly"
                    v-model="x.licenseExpiryDate"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="x.licenseExpiryDate"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.licenseDateBool.save(x.licenseExpiryDate)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">
                Vehicle Registration/ MULKIYA No.*
              </div>
              <div class="heading">
                <v-text-field
                  label="Vehicle Registration / MULKIYA No.*"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  v-model="x.vehicleReg"
                  single-line
                  :value="x.vehicleReg"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="readonly" sm6 md3>
              <a
                v-if="
                  x.vehicleRegImage &&
                  x.vehicleRegImage.substr(
                    x.vehicleRegImage.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.vehicleRegImage"
                title="Vehicle Registration Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-if="
                  x.vehicleRegImage &&
                  x.vehicleRegImage.substr(
                    x.vehicleRegImage.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.vehicleRegImage"
                title="Vehicle Registration Image"
                target="_blank"
                class
              >
                <img
                  :src="x.vehicleRegImage"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="!readonly" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Registration Picture</button>
                <input
                  ref="fileVehicleReg"
                  type="file"
                  name="myfile"
                  @change="vehicleRegistrationChanged"
                />
              </div>
              <div v-if="x.vehicleRegImage" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.vehicleRegImage &&
                    x.vehicleRegImage.substr(
                      x.vehicleRegImage.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.vehicleRegImage"
                  title="Vehicle Registration Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.vehicleRegImage &&
                    x.vehicleRegImage.substr(
                      x.vehicleRegImage.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.vehicleRegImage"
                  title="Vehicle Registration Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.vehicleRegImage"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="readonly" sm6 md3>
              <a
                v-if="
                  x.vehicleRegImageBack &&
                  x.vehicleRegImageBack.substr(
                    x.vehicleRegImageBack.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.vehicleRegImageBack"
                title="Vehicle Registration Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-if="
                  x.vehicleRegImageBack &&
                  x.vehicleRegImageBack.substr(
                    x.vehicleRegImageBack.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.vehicleRegImageBack"
                title="Vehicle Registration Image"
                target="_blank"
                class
              >
                <img
                  :src="x.vehicleRegImageBack"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex xs12 class="pr-4" v-if="!readonly" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Registration Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileVehicleRegB"
                  @change="vehicleRegistrationChangedBack"
                />
              </div>
              <div v-if="x.vehicleRegImageBack" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.vehicleRegImageBack &&
                    x.vehicleRegImageBack.substr(
                      x.vehicleRegImageBack.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.vehicleRegImageBack"
                  title="Vehicle Registration Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.vehicleRegImageBack &&
                    x.vehicleRegImageBack.substr(
                      x.vehicleRegImageBack.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.vehicleRegImageBack"
                  title="Vehicle Registration Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.vehicleRegImageBack"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3" xs12 md3>
              <div class="subheading muted caption">Registarion Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="registrationDateBool"
                  lazy
                  v-model="registrationDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :disabled="readonly"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="x.regExpiryDate"
                >
                  <v-text-field
                    slot="activator"
                    required
                    single-line
                    class="pt-0 currencyTitle"
                    :label="x.showDate || 'Registarion Expiry**'"
                    :disabled="readonly"
                    :rules="[rules.required]"
                    v-model="x.regExpiryDate"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="x.regExpiryDate"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.registrationDateBool.save(x.regExpiryDate)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3 v-if="x.borderType != 'Local'">
              <div class="subheading muted caption">Vehicle Insurance No.</div>
              <div class="heading">
                <v-text-field
                  label="Vehicle Insurance No."
                  :disabled="readonly"
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="x.vehicleInsurance"
                  :value="x.vehicleInsurance"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="readonly && x.borderType != 'Local'"
              sm6
              md3
            >
              <a
                v-if="
                  x.vehicleInsuranceImage &&
                  x.vehicleInsuranceImage.substr(
                    x.vehicleInsuranceImage.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.vehicleInsuranceImage"
                title="Vehicle Insurance Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>

              <a
                v-if="
                  x.vehicleInsuranceImage &&
                  x.vehicleInsuranceImage.substr(
                    x.vehicleInsuranceImage.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.vehicleInsuranceImage"
                title="Vehicle Insurance Image"
                target="_blank"
                class
              >
                <img
                  :src="x.vehicleInsuranceImage"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="!readonly && x.borderType != 'Local'"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Insurance Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileInsurance"
                  @change="vehicleInsuranceChanged"
                />
              </div>
              <div v-if="x.vehicleInsuranceImage" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.vehicleInsuranceImage &&
                    x.vehicleInsuranceImage.substr(
                      x.vehicleInsuranceImage.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.vehicleInsuranceImage"
                  title="Vehicle Insurance Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.vehicleInsuranceImage &&
                    x.vehicleInsuranceImage.substr(
                      x.vehicleInsuranceImage.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.vehicleInsuranceImage"
                  title="Vehicle Insurance Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.vehicleInsuranceImage"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              sm6
              md3
              v-if="x.borderType != 'Local'"
            ></v-flex>
            <v-flex class="pr-3" xs12 md3 v-if="x.borderType != 'Local'">
              <div
                v-if="this.x.jobType == 1 && x.workingCountry != 'PAK'"
                class="subheading muted caption"
              >
                Insurance Expiry*
              </div>
              <div
                v-if="this.x.jobType == 1 && x.workingCountry == 'PAK'"
                class="subheading muted caption"
              >
                Insurance Expiry
              </div>
              <div class="heading">
                <v-menu
                  ref="insuranceDateBool"
                  lazy
                  v-model="insuranceDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :disabled="readonly"
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="x.insuranceExpiryDate"
                >
                  <v-text-field
                    slot="activator"
                    :label="
                      x.showDate || x.workingcountry != 'PAK'
                        ? 'Insurance Expiry*'
                        : 'Insurance Expiry'
                    "
                    class="pt-0 currencyTitle"
                    single-line
                    required
                    :disabled="readonly"
                    v-model="x.insuranceExpiryDate"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="x.insuranceExpiryDate"
                    :min="dateToday"
                    class="minHeight"
                    @change="
                      $refs.insuranceDateBool.save(x.insuranceExpiryDate)
                    "
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3 v-if="x.borderType != 'Local'">
              <div class="subheading muted caption">Vehicle Auth Letter</div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  single-line
                  class="pt-0 currencyTitle"
                  label="Vehicle Auth Letter "
                  v-model="x.vehicleAuthLetter"
                  :value="x.vehicleAuthLetter"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="readonly && x.borderType != 'Local'"
              sm6
              md3
            >
              <a
                v-if="
                  x.vehicleAuthLetterImage &&
                  x.vehicleAuthLetterImage.substr(
                    x.vehicleAuthLetterImage.lastIndexOf('.') + 1
                  ) === 'pdf'
                "
                :href="x.vehicleInsuranceImage"
                title="Vehicle Auth Letter Image"
                target="_blank"
                class
              >
                <img src="../../assets/pfd.png" />
              </a>
              <a
                v-if="
                  x.vehicleAuthLetterImage &&
                  x.vehicleAuthLetterImage.substr(
                    x.vehicleAuthLetterImage.lastIndexOf('.') + 1
                  ) !== 'pdf'
                "
                :href="x.vehicleAuthLetterImage"
                title="Vehicle Auth Letter Image"
                target="_blank"
                class
              >
                <img
                  :src="x.vehicleAuthLetterImage"
                  class="thumb-img"
                  height="70px"
                  width="70px"
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              v-if="!readonly && x.borderType != 'Local'"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Auth Letter Proof</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileAuth"
                  @change="vehicleAuthLetterChanged"
                />
              </div>
              <div v-if="x.vehicleAuthLetterImage" class="upload-btn-wrapper">
                <a
                  v-if="
                    x.vehicleAuthLetterImage &&
                    x.vehicleAuthLetterImage.substr(
                      x.vehicleAuthLetterImage.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="x.vehicleAuthLetterImage"
                  title="Vehicle Auth Letter Image"
                  target="_blank"
                  class
                >
                  <img src="../../assets/pfd.png" />
                </a>
                <a
                  v-if="
                    x.vehicleAuthLetterImage &&
                    x.vehicleAuthLetterImage.substr(
                      x.vehicleAuthLetterImage.lastIndexOf('.') + 1
                    ) !== 'pdf'
                  "
                  :href="x.vehicleAuthLetterImage"
                  title="Vehicle Auth Letter Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="x.vehicleAuthLetterImage"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              sm6
              md3
              v-if="x.borderType != 'Local'"
            ></v-flex>
            <v-flex class="pr-3" xs12 md3 v-if="x.borderType != 'Local'">
              <div class="subheading muted caption">Auth Letter Expiry</div>
              <div class="heading">
                <v-menu
                  ref="authDateBool"
                  lazy
                  v-model="authDateBool"
                  :close-on-content-click="false"
                  :disabled="readonly"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="x.authLetterExpiryDate"
                >
                  <v-text-field
                    slot="activator"
                    required
                    :label="x.showDate || 'Auth Letter Expiry'"
                    single-line
                    :disabled="readonly"
                    v-model="x.authLetterExpiryDate"
                    prepend-icon="event"
                    class="pt-0 currencyTitle"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="x.authLetterExpiryDate"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.authDateBool.save(x.authLetterExpiryDate)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="this.x.jobType == 1">
            <v-flex
              xs12
              class="pr-4 pt-3"
              sm6
              md2
              v-if="x.workingCountry != 'PAK'"
            >
              <div class="subheading muted caption">Prefix*</div>
              <div class="heading">
                <v-autocomplete
                  class="pt-0 currencyTitle"
                  :disabled="readonly"
                  label="Prefix*"
                  :items="prefixTrucks"
                  :rules="[rules.required]"
                  v-model="x.truckPrefix"
                  single-line
                  autocomplete="offfadsf"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4 pt-3"
              sm6
              md2
              v-if="x.workingCountry == 'KSA'"
            >
              <div class="subheading muted caption">Prefix* (in Arabic)</div>
              <div class="heading">
                <v-text-field
                  class="pt-0 currencyTitle arabic"
                  :disabled="readonly"
                  label="Prefix* (in Arabic)"
                  v-model="x.arabictruckPrefix"
                  :value="x.arabictruckPrefix"
                  :rules="[rules.required, rules.arabicLanguage]"
                  single-line
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4 pt-3"
              sm6
              md3
              v-if="x.workingCountry == 'PAK'"
            >
              <div class="subheading muted caption">Truck Prefix*</div>
              <div class="heading">
                <v-text-field
                  label="Truck Prefix*"
                  single-line
                  :disabled="readonly"
                  v-model="x.truckPrefix"
                  :value="x.truckPrefix"
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4 pt-3" sm6 md3>
              <div class="subheading muted caption">Truck Number*</div>
              <div class="heading">
                <v-text-field
                  :rules="[rules.required]"
                  :disabled="readonly"
                  label="Truck Number*"
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="x.truckNumber"
                  :value="x.truckNumber"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              v-if="x.workingCountry == 'KSA'"
              class="pr-4 pt-3"
              sm6
              md3
            >
              <div class="subheading muted caption">
                Truck Number* (in Arabic)
              </div>
              <div class="heading">
                <v-text-field
                  :rules="[rules.required]"
                  :disabled="readonly"
                  label="Truck Number* (in Arabic)"
                  single-line
                  class="pt-0 currencyTitle arabic"
                  v-model="x.arabictruckNumber"
                  :value="x.arabictruckNumber"
                />
              </div>
            </v-flex>

            <v-flex xs12 class="pr-4 pt-3" sm6 md4>
              <div class="subheading muted caption">
                Truck Capacity in Tonnes*
              </div>
              <div class="heading">
                <v-text-field
                  :rules="[rules.required, rules.greaterthan]"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  label="Truck Capacity in Tonnes*"
                  v-model="x.truckCapacity"
                  single-line
                  :value="x.truckCapacity"
                />
              </div>
            </v-flex>
            <v-flex class="pr-3 pt-3" xs12 md4>
              <div class="subheading muted caption">Category of Truck*</div>
              <div class="heading">
                <v-select
                  :items="trucks"
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  :disabled="readonly"
                  label="Category of truck*"
                  v-model="x.truckType"
                  @change="getsubTruckType(x.truckType)"
                  single-line
                />
              </div>
            </v-flex>
            <v-flex class="pr-3 pt-3" xs12 md4>
              <div class="subheading muted caption">Sub-category of Truck*</div>
              <div class="heading">
                <v-select
                  :items="trucksSub"
                  class="pt-0 currencyTitle"
                  label="Category-sub of truck*"
                  :rules="[rules.required]"
                  v-model="x.subTruckType"
                  :disabled="readonly"
                  single-line
                />
              </div>
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />
          <div class="heading primary-clr pt-3">
            {{
              x.workingCountry != "PAK"
                ? "Sponsor Information"
                : "Guarantor Information"
            }}
          </div>
          <v-layout wrap>
            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                {{
                  x.workingCountry != "PAK" ? "Sponsor Name" : "Guarantor Name"
                }}
              </div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  :label="
                    x.workingCountry != 'PAK'
                      ? 'Sponsor Name'
                      : 'Guarantor Name'
                  "
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="x.sponsorName"
                  :value="x.sponsorName"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                {{
                  x.workingCountry != "PAK"
                    ? "Sponsor Phone number"
                    : "Guarantor Phone number"
                }}
              </div>
              <div class="heading">
                <v-text-field
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  :label="
                    x.workingCountry != 'PAK'
                      ? 'Sponsor Phone number'
                      : 'Guarantor Phone number'
                  "
                  single-line
                  v-model="x.sponsorPhone"
                  :value="x.sponsorPhone"
                />
              </div>
            </v-flex>
          </v-layout>
          <div
            class="my-3"
            v-if="haserror"
            style="width: 50%; margin: auto; text-align: center"
          >
            <v-alert v-model="haserror" class="py-2" outline color="red">{{
              error
            }}</v-alert>
          </div>
          <v-snackbar
            :timeout="4000"
            bottom
            color="red darken-2"
            v-model="errorCropper"
            class="white--text"
            v-if="errorCropper"
            >{{ errorCropper }}</v-snackbar
          >
          <hr class="divider-rule" />
          <v-layout>
            <v-spacer />
            <v-btn
              size="large"
              color="orange darken-1"
              style="color: white"
              v-if="!readonly"
              :disabled="loading"
              @click.native="checkUpdate()"
              >Update Profile</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-card>
      <v-dialog
        v-if="updateSuccess !== null"
        v-model="updateSuccess"
        max-width="390"
      >
        <v-card>
          <v-card-text>
            <div class="heading py-3 px-3">
              {{ updateSuccess }}
              <v-icon color="green" small>check_circle</v-icon>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              flat
              @click.native="
                updateSuccess = null;
                readonly = true;
              "
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmationDialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar
            dark
            style="background-color: #fee9d0; color: black; padding-left: 100px"
            flat
          >
            <v-toolbar-title>Confirmation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-spacer class="spacerclass">
            <v-card-text style="text-align: center" class="pa-4">
              <div class="pb-4">
                <img
                  src="../../assets/Character-sitting.png"
                  height="300"
                  width="300"
                />
              </div>
              <div>
                Are you sure want to change driver type? <br />Deselecting will
                unassign all transporters.
              </div>
            </v-card-text>

            <v-card-actions class="pt-2 pb-5" style="justify-content: center">
              <v-btn
                medium
                style="text-transform: none; !important"
                color="red white--text "
                darken-1
                :disabled="loading"
                text
                @click="noConfirmation()"
                >No</v-btn
              >

              <v-btn
                medium
                style="text-transform: none; !important"
                color="green white--text "
                darken-1
                @click="yesConfirmation()"
                :disabled="loading"
                text
                >Yes</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-dialog>
    </div>
  </v-form>
</template>

<script>
import {
  TruckArray,
  countries,
  TruckPrefix,
  TruckTypes,
  StorageKeys,
  UserTypes,
} from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import ImageCropper from "@/components/Common/ImageCropper.vue";
import moment from "moment";
import Loading from "vue-loading-overlay";
import { createJob, transporter, getUserById } from "../../constants/api-urls";
import "vue-loading-overlay/dist/vue-loading.min.css";
export default {
  created() {
    setTimeout(() => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          this.initLoadingAutocomplete("1");
        };
        script.src =
          "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
        document.head.appendChild(script);
      });
    }, 2500);
    this.fetchBank();
    this.$eventBus.$on("error-image", () => {
      this.errorCropper =
        "Please upload image of file type png , jpg ,jpeg or pdf!";
    });
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "driver-detail-profile") {
        this.$router.push(`/drivers`);
      }
      this.x.workingCountry = val.value;
    });
    this.$eventBus.$on("crop-profile-image", (cropImage) => {
      if (this.status == 1) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.profilePicURL = cropImage;
        this.createCropImage(cropImage, 1);
      }
    });
    this.$eventBus.$on("crop-national-image", (cropImage) => {
      if (this.status == 2) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.nationalIdImage = cropImage;
        this.createCropImage(cropImage, 2);
      }
    });
    this.$eventBus.$on("crop-passport-image", (cropImage) => {
      if (this.status == 3) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.passPortImage = cropImage;
        this.createCropImage(cropImage, 3);
      }
    });
    this.$eventBus.$on("crop-license-image", (cropImage) => {
      if (this.status == 4) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.licenseImage = cropImage;
        this.createCropImage(cropImage, 4);
      }
    });
    this.$eventBus.$on("crop-vehicleRegImage-image", (cropImage) => {
      if (this.status == 5) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.vehicleRegImage = cropImage;
        this.createCropImage(cropImage, 5);
      }
    });
    this.$eventBus.$on("crop-vehicleInsuranceImage-image", (cropImage) => {
      if (this.status == 6) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.vehicleInsuranceImage = cropImage;
        this.createCropImage(cropImage, 6);
      }
    });
    this.$eventBus.$on("crop-vehicleAuthLetterImage-image", (cropImage) => {
      if (this.status == 7) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.vehicleAuthLetterImage = cropImage;
        this.createCropImage(cropImage, 7);
      }
    });
    this.$eventBus.$on("crop-licenseImageBack-image", (cropImage) => {
      if (this.status == 8) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.licenseImageBack = cropImage;
        this.createCropImage(cropImage, 8);
      }
    });
    this.$eventBus.$on("crop-vehicleRegImageBack-image", (cropImage) => {
      if (this.status == 9) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.vehicleRegImageBack = cropImage;
        this.createCropImage(cropImage, 9);
      }
    });
    this.$eventBus.$on("crop-emiratesIDImageFront-image", (cropImage) => {
      if (this.status == 10) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.emiratesIDImageFront = cropImage;
        this.createCropImage(cropImage, 10);
      }
    });
    this.$eventBus.$on("crop-emiratesIDImageBack-image", (cropImage) => {
      if (this.status == 11) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.x.emiratesIDImageBack = cropImage;
        this.createCropImage(cropImage, 11);
      }
    });

    this.$eventBus.$on("cropper-close", () => {
      this.cropperModal = false;
    });
    this.UserTypes = UserTypes;
    this.countryCodes = countries;
    this.trucks = TruckArray;
    const { id = null } = this.$route.params;
    this.id = Number(id);
    this.loading = true;
    const { serverUrl } = this.constants;
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    var obj = {
      driverId: this.id.toString(),
    };
    let url = createJob;
    this.axios
      .post(this.constants.apiUrl + `/dmsAdmin/driverDetails`, obj, config)
      .then(
        (response) => {
          const driversInfo = response.data.data;
          this.$eventBus.$emit("driver-Detail", { driversInfo });

          for (let i in driversInfo) {
            if (this.x.hasOwnProperty(i)) {
              this.x[i] = driversInfo[i];
            }
          }
          this.x.type = driversInfo.ownerType;
          this.x.transporterId = driversInfo.ownerId;
          this.x.borderType = driversInfo.borderType;
          this.x.workingCountry = driversInfo.workingCountry;
          this.getTransporterList();
          this.x.arabictruckNumber = driversInfo.arabicTruckNo;
          this.x.arabictruckPrefix = driversInfo.arabicTruckPrefix;
          if (this.x.jobType == "2") {
            this.x.type = driversInfo.ownerType;
            (this.x.FlightName = driversInfo.flightName),
              (this.x.WesselNo = driversInfo.truckNumber),
              (this.x.TrackingLinkAir = driversInfo.trackingLink),
              (this.x.AirCargoDetails = driversInfo.flightDetail);
          }
          if (this.x.jobType == "3") {
            this.x.type = driversInfo.ownerType;
            (this.x.WesselNoSea = driversInfo.truckNumber),
              (this.x.TrackingLinkSea = driversInfo.trackingLink),
              (this.x.SeaCargoDetails = driversInfo.flightDetail);
          }
          if (this.x.nationalIdExpiryDate)
            this.x.nationalIdExpiryDate = this.getTime3(
              this.x.nationalIdExpiryDate
            );
          if (this.x.nationalId) {
            this.x.nationalId = this.x.nationalId.replace(/-/g, "");
          }

          if (this.x.passportExpiryDate)
            this.x.passportExpiryDate = this.getTime3(
              this.x.passportExpiryDate
            );
          if (this.x.emiratesIDExpiryDate)
            this.x.emiratesIDExpiryDate = this.getTime3(
              this.x.emiratesIDExpiryDate
            );

          if (this.x.licenseExpiryDate)
            this.x.licenseExpiryDate = this.getTime3(this.x.licenseExpiryDate);

          if (this.x.regExpiryDate)
            this.x.regExpiryDate = this.getTime3(this.x.regExpiryDate);

          if (this.x.insuranceExpiryDate)
            this.x.insuranceExpiryDate = this.getTime3(
              this.x.insuranceExpiryDate
            );

          if (this.x.authLetterExpiryDate)
            this.x.authLetterExpiryDate = this.getTime3(
              this.x.authLetterExpiryDate
            );
          if (!response.data.data.jobType) {
            this.x.jobType = "1";
          }

          this.setDriver(driversInfo.firstName + " " + driversInfo.lastName);
          if (this.x.truckType || this.x.truckType == 0) {
            this.x.truckType = this.x.truckType.toString() || "";
            this.getsubTruck(this.x.truckType, this.x.subTruckType);
          }

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.error = this.$eventBus.parse(error);
        }
      );

    this.prefixTrucks = TruckPrefix;
    const url2 = `${this.constants.apiUrl}/driver/getPreferredLocation`;
    this.axios.get(url2).then((response) => {
      if (response.status === 200) {
        this.loading = false;

        response.data.data.forEach((e) => {
          this.preferredLocations.push({ value: e.name, text: e.name });
        });
      }
    });
  },
  components: {
    Loading,
    ImageCropper,
  },
  data() {
    return {
      mainError: null,
      errorCropper: null,
      bankInfo: [],
      city1Error: null,
      invoiceDay: null,
      mainError1: null,
      transporters: [],
      transporterId: [],
      valid: true,
      confirmationDialog: false,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      operation: "",
      cropperModal: false,
      countrySortName: "",
      cropFileUrl: "",
      status: null,
      lazy: false,
      UserTypes: [],
      borderTypes: [
        { text: "Local Driver", value: "Local" },
        { text: "Cross Country Driver", value: "OutOfBorder" },
      ],
      multipleCountry: [],
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        requiredLocation: (value) =>
          (v != null && !!value.length) || "This field is required.",
        greaterthan: (value) => value >= 1 || "Please enter valid number",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",

        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No. ",
        onlyEnglish: (v) =>
          /^[a-zA-Z\s]*$/.test(v) || "Please Enter Valid value",
        arabicLanguage: (v) =>
          /^([أ-ي]| [\u0600-\u06FF]|[@~`!@#$%^&*()_=+]|[الآخرين]|[ "]|['])+$/.test(
            v
          ) || "Please Enter Arabic language",
      },
      loading: false,
      id: null,

      updateSuccess: null,
      readonly: true,
      error: null,
      nationalIdDateBool: false,
      passportDateBool: false,
      emiratesDateBool: false,
      licenseDateBool: false,
      registrationDateBool: false,
      insuranceDateBool: false,
      authDateBool: false,
      x: {
        // ---------------- EXPIRY DATES -------------

        nationalIdExpiryDate: "",
        passportExpiryDate: null,
        licenseExpiryDate: null,
        emiratesIDExpiryDate: null,
        regExpiryDate: null,
        insuranceExpiryDate: null,
        authLetterExpiryDate: null,
        // -------------------------------------------
        firstName: null,
        arabicFirstName: null,
        borderType: "",
        type: [],
        transporterId: [],
        lastName: null,
        arabictruckPrefix: null,
        arabictruckNumber: "",
        arabicLastName: null,
        jobType: "",
        workingCountry: "",
        driverPaymentDuration: null,
        TrackingLinkAir: "",
        TrackingLinkSea: "",
        SeaCargoDetails: "",
        AirCargoDetails: "",
        WesselNo: "",
        WesselNoSea: "",
        FlightName: "",

        availabilityStatus: true,
        dob: moment().unix(),
        nationality: null,
        countryCode: null,
        secondaryCountryCode: "",
        thirdCountryCode: "",
        phoneNo: null,
        secondaryPhoneNo: null,
        thirdPhoneNo: null,
        secondaryPhoneNo: null,
        address: null,
        bankName: "",
        bankHolderName: "",
        city: null,
        postCode: null,
        profilePicURL: null,
        nationalId: "",
        nationalIdImage: "",
        passPortNumber: null,
        passPortImage: null,
        licenseNumber: null,
        vatNumber: "",
        licenseImage: null,
        licenseImageBack: null,
        emiratesID: null,
        emiratesIDImageFront: null,
        emiratesIDImageBack: null,

        sponsorName: null,
        sponsorPhone: null,
        truckType: null,
        subTruckType: null,

        truckCapacity: null,
        vehicleNumber: null,
        truckNumber: "",
        truckPrefix: null,
        preferredLocation: [],
        vehicleReg: null,
        vehicleRegImage: null,
        vehicleRegImageBack: null,

        vehicleInsurance: null,
        vehicleInsuranceImage: null,
        vehicleAuthLetter: null,
        vehicleAuthLetterImage: null,
        fingerprint: null,
      },
      trucks: [],
      truckers: [],
      trucksSub: [],
      truckCat: null,
      subTruckType: null,
      truckType: null,
      preferredLocations: [],
      prefixTrucks: [],
      menu: false,
      dateOfBirth: null,
      nationalities: [
        {
          text: "Indian",
          value: "Indian",
        },
        {
          text: "Pakistani",
          value: "pakistani",
        },
        {
          text: "Sudani",
          value: "sudani",
        },
        {
          text: "Yemeni",
          value: "yemeni",
        },
        {
          text: "Egyptian",
          value: "egyptian",
        },
        {
          text: "Nepali",
          value: "nepali",
        },
        {
          text: "Sri Lankan",
          value: "sri lankan",
        },
        {
          text: "Bangladeshi",
          value: "bangladeshi",
        },
        {
          text: "Syrian",
          value: "syrian",
        },
        {
          text: "Saudi Arabian",
          value: "saudi arabian",
        },
        {
          text: "Omani",
          value: "omani",
        },
        {
          text: "Bahraini",
          value: "bahraini",
        },
        {
          text: "Kuwaiti",
          value: "kuwaiti",
        },
        {
          text: "Others",
          value: "others",
        },
      ],
      languages: [
        {
          text: "English",
          value: "english",
        },
        {
          text: "English",
          value: "english",
        },
        {
          text: "Hindi",
          value: "hindi",
        },
        {
          text: "Arabic",
          value: "arabic",
        },
      ],
      countryCodes: [],
    };
  },
  watch: {
    truckCat(val) {
      var x = this.trucks.filter((v) => {
        if (v.value === val) return v;
      });
      this.trucksSub = x[0].sub;
    },

    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    updateCity() {
      if (this.city1Error == false) {
        this.city1Error = true;
      }
    },
    initLoadingAutocomplete(n) {
      // console.log(n);
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1")
      );
      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        // console.log(place);
        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.city1Error = false;
        } else {
          this.city1Error = true;
        }
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          console.log(x);
          this.x.city = x[0];
        } else {
          this.x.city = y[0];
        }
      });

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.

      // console.log(this.dropoff);
    },

    fetchBank() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      let { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/bankListForDriver`, body, config)
        .then(
          (response) => {
            let data = response.data.data;
            console.log(data);
            this.bankInfo = data;
            // data.forEach((element) => {
            //   this.bankInfo.push({
            //     text: element.bankName,
            //     value: element._id,
            //   });
            // });
            // console.log(this.banking.info);

            // this.bankName = response.data.data[2].bankName;
          },
          (error) => {
            this.error = "Something went wrong. Please try again later!";
            this.loading = false;
          }
        );
    },
    getDriverDetails() {
      const { id = null } = this.$route.params;
      this.id = Number(id);
      this.loading = true;
      const { serverUrl } = this.constants;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      var obj = {
        driverId: this.id.toString(),
      };
      let url = createJob;
      this.axios
        .post(this.constants.apiUrl + `/dmsAdmin/driverDetails`, obj, config)
        .then(
          (response) => {
            const driversInfo = response.data.data;
            this.$eventBus.$emit("driver-Detail", { driversInfo });

            for (let i in driversInfo) {
              if (this.x.hasOwnProperty(i)) {
                this.x[i] = driversInfo[i];
              }
            }
            console.log(driversInfo);
            this.city1Error = false;
            this.x.type = driversInfo.ownerType;
            this.x.transporterId = driversInfo.ownerId;
            this.x.borderType = driversInfo.borderType;
            this.x.workingCountry = driversInfo.workingCountry;
            if (driversInfo.bankName) {
              this.x.bankName = driversInfo.bankName;
            }
            if (driversInfo.bankHolderName) {
              this.x.bankHolderName = driversInfo.bankHolderName;
            }

            this.getTransporterList();

            if (this.x.jobType == "2") {
              (this.x.FlightName = driversInfo.flightName),
                (this.x.WesselNo = driversInfo.truckNumber),
                (this.x.TrackingLinkAir = driversInfo.trackingLink),
                (this.x.AirCargoDetails = driversInfo.flightDetail);
            }
            if (this.x.jobType == "3") {
              (this.x.WesselNoSea = driversInfo.truckNumber),
                (this.x.TrackingLinkSea = driversInfo.trackingLink),
                (this.x.SeaCargoDetails = driversInfo.flightDetail);
            }
            if (this.x.nationalIdExpiryDate)
              this.x.nationalIdExpiryDate = this.getTime3(
                this.x.nationalIdExpiryDate
              );
            if (this.x.nationalId) {
              this.x.nationalId = this.x.nationalId.replace(/-/g, "");
            }

            if (this.x.passportExpiryDate)
              this.x.passportExpiryDate = this.getTime3(
                this.x.passportExpiryDate
              );
            if (this.x.emiratesIDExpiryDate)
              this.x.emiratesIDExpiryDate = this.getTime3(
                this.x.emiratesIDExpiryDate
              );

            if (this.x.licenseExpiryDate)
              this.x.licenseExpiryDate = this.getTime3(
                this.x.licenseExpiryDate
              );

            if (this.x.regExpiryDate)
              this.x.regExpiryDate = this.getTime3(this.x.regExpiryDate);

            if (this.x.insuranceExpiryDate)
              this.x.insuranceExpiryDate = this.getTime3(
                this.x.insuranceExpiryDate
              );

            if (this.x.authLetterExpiryDate)
              this.x.authLetterExpiryDate = this.getTime3(
                this.x.authLetterExpiryDate
              );
            if (!response.data.data.jobType) {
              this.x.jobType = "1";
            }

            this.setDriver(driversInfo.firstName + " " + driversInfo.lastName);
            if (this.x.truckType || this.x.truckType == 0) {
              this.x.truckType = this.x.truckType.toString() || "";
              this.getsubTruck(this.x.truckType, this.x.subTruckType);
            }

            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.error = this.$eventBus.parse(error);
          }
        );
    },
    errorFalse() {
      this.mainError1 = null;
    },
    getTransporterList() {
      this.userId = localStorage.getItem("userId");

      delete this.axios.defaults.headers.common["token"];
      let body = {
        workingCountry: this.x.workingCountry,
        type: "1",
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios
        .post(
          this.constants.apiUrl + `/dmsAdmin/transporterForDriverDetail `,
          body,
          config
        )
        .then(
          (response) => {
            this.loading = false;

            response.data.data.list.forEach((e) => {
              this.transporters.push({ value: e._id, text: e.transporterName });
            });
          },
          (error) => {
            this.loading = false;
          }
        );
    },
    yesConfirmation() {
      if (this.mainError) {
        this.mainError = null;
      }
      if (this.mainError1) {
        this.mainError1 = null;
      }
      this.confirmationDialog = false;
    },
    noConfirmation() {
      if (this.mainError1) {
        this.mainError1 = null;
      }
      if (this.x.type.includes("Transporter")) {
        if (this.mainError) {
          this.mainError = null;
        }
        this.x.type = ["Owner"];
        this.x.transporterId = [];
        this.confirmationDialog = false;
      } else if (!this.x.type.includes("Transporter")) {
        if (this.x.type.includes("Owner")) {
          this.x.type = ["Owner", "Transporter"];
        }
        if (!this.x.type.includes("Owner")) {
          this.x.type = ["Transporter"];
        }
        this.confirmationDialog = false;
      }
    },
    check: function (e) {
      if (this.mainError) {
        this.mainError = null;
      }
      if (this.mainError1) {
        this.mainError1 = null;
      }
      if (!this.x.type.includes("Transporter")) {
        this.confirmationDialog = true;
      } else {
        this.x.transporterId = [];
      }
    },
    async checkEdit() {
      this.error = null;
      this.haserror = false;
      this.operationName = "edit-profile-driver";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.readonly = !this.readonly;
        this.getDriverDetails();
      } else {
        return;
      }
    },
    async checkUpdate() {
      this.error = null;
      this.haserror = false;
      this.loading = true;

      this.operationName = "edit-profile-driver";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.update();
      } else {
        this.loading = false;
        this.readonly = !this.readonly;
        return;
      }
    },
    getUserById() {
      this.userId = localStorage.getItem("userId");
      this.loading = true;
      let url = getUserById;
      delete this.axios.defaults.headers.common["token"];
      let body = {
        userId: localStorage.getItem("userId"),
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          response.data.data.countrySortName.forEach((e) => {
            this.multipleCountry.push(e);
          });
        },
        (error) => {
          this.error = "Failed to fetch country";
        }
      );
    },
    getSub(val) {
      var x = this.trucksub.filter((t) => {
        if (t.value === val) return;
      });
    },
    getsubTruckType(val) {
      this.x.subTruckType = null;
      if (this.x.truckType || this.x.truckType == 0) {
        var x = this.trucks.filter((v) => {
          if (v.value == val) return v;
        });
        this.trucksSub = x[0].sub;
        // var y = this.trucksSub.filter((t) => {
        //   if (t.value == subval) return t;
        // });

        // this.x.subTruckType = y[0].value;
      }
    },

    getsubTruck(val, subval) {
      if (this.x.truckType || this.x.truckType == 0) {
        var x = this.trucks.filter((v) => {
          if (v.value == val) return v;
        });
        this.trucksSub = x[0].sub;
        var y = this.trucksSub.filter((t) => {
          if (t.value == subval) return t;
        });

        this.x.subTruckType = y[0].value;
      }
    },

    profilePicChange(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileUploadProfile.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImageProfile(files[0], 1);
    },
    nationalIDChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileNationalId.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 2);
    },
    passportChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.filePassport.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 3);
    },
    licenseChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileLicense.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 4);
    },
    vehicleRegistrationChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileVehicleReg.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 5);
    },
    vehicleInsuranceChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileInsurance.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 6);
    },
    vehicleAuthLetterChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileAuth.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 7);
    },
    licenseChangedBack(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileLicenseB.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 8);
    },
    vehicleRegistrationChangedBack(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileVehicleRegB.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 9);
    },

    emiratesIDChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileEmirates.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 10);
    },
    emiratesIDChangedBack(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileEmiratesB.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 11);
    },
    createImageProfile(file, status) {
      this.error = null;
      this.errorCropper = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.errorCropper = "Please upload image with size less than 5MB!";
        this.processing = false;

        return false;
      }
      if (
        file.name
          .toString()
          .substr(file.name.toString().lastIndexOf(".") + 1) == "jfif"
      ) {
        this.errorCropper =
          "Please upload image of file type png , jpg or jpeg!";
        this.processing = false;
        return false;
      }

      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg"
      ) {
        //   this.$refs.fileUpload.value = "";

        this.errorCropper =
          "Please upload image of file type png , jpg or jpeg !";

        this.processing = false;
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.loading = false;
        this.upload(file, status);
      }
    },
    createImage(file, status) {
      this.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.error = "Please upload image with size less than 5MB!";
        this.processing = false;

        return false;
      }
      if (
        file.name
          .toString()
          .substr(file.name.toString().lastIndexOf(".") + 1) == "jfif"
      ) {
        this.errorCropper =
          "Please upload image of file type png , jpg ,jpeg or pdf!";
        this.processing = false;
        return false;
      }

      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        //   this.$refs.fileUpload.value = "";

        this.errorCropper =
          "Please upload image of file type png , jpg ,jpeg or pdf!";

        this.processing = false;
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.loading = false;
        this.upload(file, status);
      }
    },
    upload(file, statusChange) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;

          if (statusCode === 200) {
            this.loading = false;
            // this.cropFileUrl = response.data.data.original;

            if (statusChange == 1) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.profilePicURL = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.profilePicURL = response.data.data.original;
              }
            } else if (statusChange == 2) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.nationalIdImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.nationalIdImage = response.data.data.original;
              }
            } else if (statusChange == 3) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.passPortImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.passPortImage = response.data.data.original;
              }
            } else if (statusChange == 4) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                //  this.cropperModal = false;
                this.status = statusChange;
                this.x.licenseImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.licenseImage = response.data.data.original;
              }
            } else if (statusChange == 5) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.vehicleRegImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.vehicleRegImage = response.data.data.original;
              }
            } else if (statusChange == 6) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.vehicleInsuranceImage = response.data.data.original;
              } else {
                //this.cropperModal = true;
                this.status = statusChange;
                this.x.vehicleInsuranceImage = response.data.data.original;
              }
            } else if (statusChange == 7) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                //  this.cropperModal = false;
                this.status = statusChange;
                this.x.vehicleAuthLetterImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.vehicleAuthLetterImage = response.data.data.original;
              }
            } else if (statusChange == 8) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.licenseImageBack = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.licenseImageBack = response.data.data.original;
              }
            } else if (statusChange == 9) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.vehicleRegImageBack = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.vehicleRegImageBack = response.data.data.original;
              }
            } else if (statusChange == 10) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.x.emiratesIDImageFront = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.x.emiratesIDImageFront = response.data.data.original;
              }
            } else if (statusChange == 11) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                //this.cropperModal = false;
                this.status = statusChange;
                this.x.emiratesIDImageBack = response.data.data.original;
              } else {
                //this.cropperModal = true;
                this.status = statusChange;
                this.x.emiratesIDImageBack = response.data.data.original;
              }
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.loading = false;
        }
      );
    },
    createCropImage(file, status) {
      this.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.error = "Please upload image with size less than 5MB!";
        this.processing = false;

        return false;
      }

      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        //   this.$refs.fileUpload.value = "";

        this.error = "Please upload image of file type png , jpg ,jpeg or pdf!";
        this.processing = false;
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.loading = false;

        this.uploadCropImage(file, status);
      }
    },
    uploadCropImage(file, statusChange) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;
          if (statusCode === 200) {
            this.loading = false;
            this.cropFileUrl = "";
            if (statusChange == 1) {
              this.x.profilePicURL = response.data.data.original;
            } else if (statusChange == 2) {
              this.x.nationalIdImage = response.data.data.original;
            } else if (statusChange == 3) {
              this.x.passPortImage = response.data.data.original;
            } else if (statusChange == 4) {
              this.x.licenseImage = response.data.data.original;
            } else if (statusChange == 5) {
              this.x.vehicleRegImage = response.data.data.original;
            } else if (statusChange == 6) {
              this.x.vehicleInsuranceImage = response.data.data.original;
            } else if (statusChange == 7) {
              this.x.vehicleAuthLetterImage = response.data.data.original;
            } else if (statusChange == 8) {
              this.x.licenseImageBack = response.data.data.original;
            } else if (statusChange == 9) {
              this.x.vehicleRegImageBack = response.data.data.original;
            } else if (statusChange == 10) {
              this.x.emiratesIDImageFront = response.data.data.original;
            } else if (statusChange == 11) {
              this.x.emiratesIDImageBack = response.data.data.original;
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.loading = false;
        }
      );
    },
    save(date) {
      this.dob = moment(date, "YYYY-MM-DD").unix();
      this.$refs.menu.save(date);
    },
    getTime(epoch) {
      if (dob !== 0) {
        return moment().unix(dob).format("YYYY-MM-DD");
      }
    },
    getTime2(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTime3(date) {
      return moment.utc(date).format("YYYY-MM-DD");
    },
    close() {
      this.$router.go(-1);
    },
    getTruckType(truckType) {
      if (!truckType) return;
      const truck = this.truckTypes.find(
        (el) => el.value === Number(truckType)
      );
      return truck ? truck.text : "NA";
    },

    update() {
      this.mainError = null;
      this.mainError1 = null;
      let driverType = "";
      this.updateSuccess = null;
      this.loading = true;
      if (this.city1Error == true || this.city1Error == null) {
        this.x.error = "Please enter Valid City";
        return false;
      }
      if (this.$refs.form.validate()) {
        const url = `${this.constants.apiUrl}/dmsAdmin/editDriver`;
        const data = {
          driverId: this.id,
          ...this.x,
        };
        if (false) {
        } else {
          if (!data.profilePicURL) {
            this.error = "Please provide profile picture before moving ahead";
            this.loading = false;
            return false;
          }
          if (!this.x.type.length && this.x.jobType == "1") {
            this.mainError1 = "Please select checkbox";
            this.loading = false;
            return false;
          }

          if (
            this.x.type.length &&
            !this.x.transporterId &&
            this.x.jobType == "1" &&
            this.x.type.includes("Transporter")
          ) {
            this.mainError = "Please select at least one transporter";
            this.loading = false;
            return false;
          }
          if (!data.secondaryCountryCode && this.x.borderType != "Local") {
            this.error =
              "Please provide secondary country code before moving ahead";
            this.loading = false;
            return false;
          }
          if (!data.secondaryPhoneNo && this.x.borderType != "Local") {
            this.error =
              "Please provide secondary phone number before moving ahead";
            this.loading = false;
            return false;
          }

          if (
            !data.passPortImage &&
            this.x.jobType == "1" &&
            this.x.workingCountry != "PAK" &&
            this.x.borderType != "Local"
          ) {
            this.error = "Please provide passport image before moving ahead";
            this.loading = false;
            return false;
          }

          if (
            !data.emiratesIDImageFront &&
            this.x.jobType == "1" &&
            this.x.workingCountry != "PAK"
          ) {
            this.error = "Please provide Emirates ID image before moving ahead";
            this.loading = false;
            return false;
          }
          if (
            !data.emiratesIDImageBack &&
            this.x.jobType == "1" &&
            this.x.workingCountry != "PAK"
          ) {
            this.error = "Please provide Emirates ID image before moving ahead";
            this.loading = false;
            return false;
          }

          if (!data.licenseImage && this.x.jobType == "1") {
            this.error = "Please provide license image before moving ahead";
            this.loading = false;
            return false;
          }
          if (!data.licenseImageBack && this.x.jobType == "1") {
            this.error = "Please provide license image before moving ahead";
            this.loading = false;
            return false;
          }

          if (!data.vehicleRegImage && this.x.jobType == "1") {
            this.error =
              "Please provide vehicle registration image before moving ahead";
            this.loading = false;
            return false;
          }
          if (!data.vehicleRegImageBack && this.x.jobType == "1") {
            this.error =
              "Please provide vehicle registration image before moving ahead";
            this.loading = false;
            return false;
          }
          if (
            !data.vehicleInsurance &&
            this.x.jobType == "1" &&
            this.x.workingCountry != "PAK" &&
            this.x.borderType != "Local"
          ) {
            this.error = "Please provide vehicle insurance before moving ahead";
            this.loading = false;
            return false;
          }
          if (
            !data.vehicleInsuranceImage &&
            this.x.jobType == "1" &&
            this.x.workingCountry != "PAK" &&
            this.x.borderType != "Local"
          ) {
            this.error =
              "Please provide vehicle insurance image before moving ahead";
            this.loading = false;
            return false;
          }

          if (
            !data.insuranceExpiryDate &&
            this.x.jobType == "1" &&
            this.x.workingCountry != "PAK" &&
            this.x.borderType != "Local"
          ) {
            this.error =
              "Please provide vehicle insurance expiry date before moving ahead";
            this.loading = false;
            return false;
          }

          var obj = {
            driverId: data.driverId.toString(),
            // --------- required fields-----------
            firstName: data.firstName,
            lastName: data.lastName,
            jobType: data.jobType,
            countryCode: data.countryCode.dialing_code || data.countryCode,
            workingCountry: data.workingCountry,
            phoneNo: data.phoneNo,
            driverPaymentDuration: data.driverPaymentDuration.toString(),
            profilePicURL: data.profilePicURL,
          };

          if (this.x.jobType == "1") {
            if (this.x.type.length) {
              obj.ownerType = this.x.type;
              if (this.x.type.includes("Transporter")) {
                obj.transporterId = this.x.transporterId || [];
              } else {
                obj.transporterId = [];
              }
            }
          }
          if (data.bankName) {
            obj.bankName = data.bankName;
          } else {
            obj.bankName = "";
          }
          if (data.bankHolderName) {
            obj.bankHolderName = data.bankHolderName;
          } else {
            obj.bankHolderName = "";
          }
          if (this.x.jobType == "1") {
            if (this.x.workingCountry == "PAK") {
              this.x.secondaryPhoneNo
                ? (obj.secondaryPhoneNo = data.secondaryPhoneNo)
                : "";
              this.x.secondaryCountryCode
                ? (obj.secondaryCountryCode = data.secondaryCountryCode)
                : "";
              if (data.vehicleInsurance) {
                obj.vehicleInsurance = data.vehicleInsurance;
              }
              if (data.vehicleInsuranceImage) {
                obj.vehicleInsurance = data.vehicleInsurance;
              }
              if (data.insuranceExpiryDate) {
                obj.insuranceExpiryDate = data.insuranceExpiryDate;
              }
              if (data.nationalId) {
                obj.nationalId = data.nationalId;
              }
              obj.nationality = data.nationality;
              if (data.nationalIdImage) {
                obj.nationalIdImage = data.nationalIdImage;
              }
              if (data.vatNumber) {
                if (data.vatNumber.trim()) {
                  obj.vatNumber = data.vatNumber;
                }
              }
              (obj.licenseNumber = data.licenseNumber),
                (obj.licenseImage = data.licenseImage),
                (obj.licenseImageBack = data.licenseImageBack),
                (obj.truckType = data.truckType),
                (obj.subTruckType = data.subTruckType.toString()),
                (obj.truckCapacity = data.truckCapacity),
                (obj.vehicleReg = data.vehicleReg),
                (obj.vehicleRegImage = data.vehicleRegImage),
                (obj.vehicleRegImageBack = data.vehicleRegImageBack),
                (obj.vehicleNumber = data.truckPrefix
                  ? data.truckPrefix + data.truckNumber
                  : data.truckNumber),
                (obj.truckNumber = data.truckNumber),
                (obj.licenseExpiryDate = data.licenseExpiryDate),
                (obj.regExpiryDate = data.regExpiryDate);
              if (data.nationalIdExpiryDate) {
                obj.nationalIdExpiryDate = data.nationalIdExpiryDate;
              }
              obj.preferredLocation = data.preferredLocation;
            } else {
              if (this.x.borderType == "Local") {
                this.x.secondaryPhoneNo
                  ? (obj.secondaryPhoneNo = data.secondaryPhoneNo)
                  : "";
                this.x.secondaryCountryCode
                  ? (obj.secondaryCountryCode = data.secondaryCountryCode)
                  : "";

                obj.nationality = data.nationality;
                obj.borderType = data.borderType;
                if (this.x.workingCountry == "KSA") {
                  (obj.arabicFirstName = data.arabicFirstName),
                    (obj.arabicLastName = data.arabicLastName);
                  (obj.arabicTruckPrefix = data.arabictruckPrefix),
                    (obj.arabicTruckNo = data.arabictruckNumber);
                }
                if (data.vatNumber) {
                  if (data.vatNumber.trim()) {
                    obj.vatNumber = data.vatNumber;
                  }
                }
                (obj.licenseNumber = data.licenseNumber),
                  (obj.licenseImage = data.licenseImage),
                  (obj.licenseImageBack = data.licenseImageBack),
                  (obj.emiratesID = data.emiratesID),
                  (obj.emiratesIDImageFront = data.emiratesIDImageFront),
                  (obj.emiratesIDImageBack = data.emiratesIDImageBack),
                  (obj.truckType = data.truckType),
                  (obj.subTruckType = data.subTruckType.toString()),
                  (obj.truckCapacity = data.truckCapacity),
                  (obj.vehicleReg = data.vehicleReg),
                  (obj.vehicleRegImage = data.vehicleRegImage),
                  (obj.vehicleRegImageBack = data.vehicleRegImageBack),
                  (obj.vehicleNumber = data.truckPrefix
                    ? data.truckPrefix + data.truckNumber
                    : data.truckNumber),
                  (obj.truckNumber = data.truckNumber),
                  (obj.licenseExpiryDate = data.licenseExpiryDate),
                  (obj.emiratesIDExpiryDate = data.emiratesIDExpiryDate),
                  (obj.regExpiryDate = data.regExpiryDate),
                  (obj.preferredLocation = data.preferredLocation);
              } else {
                obj.borderType = data.borderType;
                if (data.nationalId) {
                  obj.nationalId = data.nationalId;
                }
                obj.nationality = data.nationality;
                if (data.nationalIdImage) {
                  obj.nationalIdImage = data.nationalIdImage;
                }
                this.x.secondaryPhoneNo
                  ? (obj.secondaryPhoneNo = data.secondaryPhoneNo)
                  : "";
                this.x.secondaryCountryCode
                  ? (obj.secondaryCountryCode = data.secondaryCountryCode)
                  : "";
                if (this.x.workingCountry == "KSA") {
                  (obj.arabicFirstName = data.arabicFirstName),
                    (obj.arabicLastName = data.arabicLastName);
                  (obj.arabicTruckPrefix = data.arabictruckPrefix),
                    (obj.arabicTruckNo = data.arabictruckNumber);
                }
                if (data.vatNumber) {
                  if (data.vatNumber.trim()) {
                    obj.vatNumber = data.vatNumber;
                  }
                }
                (obj.passPortNumber = data.passPortNumber),
                  (obj.passPortImage = data.passPortImage),
                  (obj.licenseNumber = data.licenseNumber),
                  (obj.licenseImage = data.licenseImage),
                  (obj.licenseImageBack = data.licenseImageBack),
                  (obj.emiratesID = data.emiratesID),
                  (obj.emiratesIDImageFront = data.emiratesIDImageFront),
                  (obj.emiratesIDImageBack = data.emiratesIDImageBack),
                  (obj.truckType = data.truckType),
                  (obj.subTruckType = data.subTruckType.toString()),
                  (obj.truckCapacity = data.truckCapacity),
                  (obj.vehicleReg = data.vehicleReg),
                  (obj.vehicleRegImage = data.vehicleRegImage),
                  (obj.vehicleRegImageBack = data.vehicleRegImageBack),
                  (obj.vehicleInsurance = data.vehicleInsurance);
                obj.vehicleInsurance = data.vehicleInsurance;
                (obj.vehicleNumber = data.truckPrefix
                  ? data.truckPrefix + data.truckNumber
                  : data.truckNumber),
                  (obj.truckNumber = data.truckNumber),
                  (obj.licenseExpiryDate = data.licenseExpiryDate),
                  (obj.emiratesIDExpiryDate = data.emiratesIDExpiryDate),
                  (obj.regExpiryDate = data.regExpiryDate),
                  (obj.insuranceExpiryDate = data.insuranceExpiryDate),
                  (obj.passportExpiryDate = data.passportExpiryDate);
                if (data.nationalIdExpiryDate) {
                  obj.nationalIdExpiryDate = data.nationalIdExpiryDate;
                }
                obj.preferredLocation = data.preferredLocation;
              }
            }
          }
          if (this.x.jobType == "2") {
            this.x.secondaryPhoneNo
              ? (obj.secondaryPhoneNo = data.secondaryPhoneNo)
              : "";
            this.x.secondaryCountryCode
              ? (obj.secondaryCountryCode = data.secondaryCountryCode)
              : "";
            (obj.ownerType = ["Owner"]),
              (obj.transporterId = []),
              (obj.truckNumber = data.WesselNo),
              (obj.flightName = data.FlightName),
              (obj.flightDetail = data.AirCargoDetails),
              (obj.trackingLink = data.TrackingLinkAir);
          }
          if (this.x.jobType == "3") {
            (obj.ownerType = ["Owner"]),
              this.x.secondaryPhoneNo
                ? (obj.secondaryPhoneNo = data.secondaryPhoneNo)
                : "";
            this.x.secondaryCountryCode
              ? (obj.secondaryCountryCode = data.secondaryCountryCode)
              : "";
            (obj.transporterId = []),
              (obj.truckNumber = data.WesselNoSea),
              (obj.flightDetail = data.SeaCargoDetails),
              (obj.trackingLink = data.TrackingLinkSea);
          }
          if (data.truckPrefix) {
            obj.truckPrefix = data.truckPrefix;
          }
          if (data.truckNumber == 0) {
            this.error =
              "Please provide valid truck number  before moving ahead";
            this.loading = false;
            return false;
          }

          if (data.address) {
            obj.address = data.address;
          }
          if (data.thirdCountryCode) {
            obj.thirdCountryCode =
              data.thirdCountryCode.dialing_code || data.thirdCountryCode;
          }
          if (data.thirdPhoneNo) {
            obj.thirdPhoneNo = data.thirdPhoneNo;
          }
          if (data.city) {
            obj.city = data.city;
          }
          if (data.postCode) {
            obj.postCode = data.postCode;
          }
          if (data.sponsorName) {
            obj.sponsorName = data.sponsorName;
          }
          if (data.sponsorPhone) {
            obj.sponsorPhone = data.sponsorPhone;
          }
          if (data.vehicleInsurance && this.x.borderType != "Local") {
            obj.vehicleInsurance = data.vehicleInsurance;
          }
          if (data.vehicleInsuranceImage && this.x.borderType != "Local") {
            obj.vehicleInsuranceImage = data.vehicleInsuranceImage;
          }
          if (data.vehicleAuthLetter && this.x.borderType != "Local") {
            obj.vehicleAuthLetter = data.vehicleAuthLetter;
          }
          if (data.vehicleAuthLetterImage && this.x.borderType != "Local") {
            obj.vehicleAuthLetterImage = data.vehicleAuthLetterImage;
          }
          if (data.authLetterExpiryDate && this.x.borderType != "Local") {
            obj.authLetterExpiryDate = data.authLetterExpiryDate;
          }
          if (!this.valid) {
            return;
          }

          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };

          this.axios.post(url, obj, config).then(
            (response) => {
              const { status = 400, message = "Failed to update driver" } =
                response.data;
              if (status !== 200) {
                this.readonly = true;
                this.mainError = null;
                this.loading = false;
                this.error =
                  message == "Success"
                    ? "Driver details have been successfully updated"
                    : message;
              } else {
                this.error = null;
                this.mainError = null;
                this.loading = false;
                this.updateSuccess = "Driver profile successfully updated";
              }
            },
            (error) => {
              this.error = this.$eventBus.parse(error);
              this.mainError = null;
              this.loading = false;
            }
          );
        }
      } else {
        this.loading = false;
        this.error = "Please fill all required fields in above form !";
      }
    },
    setDriver(name) {
      this.$eventBus.$emit("on-driver-profile-load", { name });
    },
  },
  computed: {
    haserror() {
      return this.error !== null;
    },
    haserrors() {
      return this.mainError !== null;
    },
    haserrors1() {
      return this.mainError1 !== null;
    },
  },
};
</script>
<style scoped>
.ft-20 {
  font-size: 20px;
}
</style>

<style scoped lang="scss">
.menu,
.menu__content {
  background: white !important;
}

.jbtn-file {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.jbtn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.doc-btn-link {
  padding: 10px;
  background: #f5853a;
  text-decoration: none;
  color: #fff;
}

.thumb-img {
  border: 2px solid darkorange;
  border-radius: 50%;
  height: 60px;
  margin-top: 10px;
  width: 60px;
}
.grey-btn {
  background-color: grey !important;
}
.minHeight {
  min-height: 300px;
}

.m-10 {
  margin: 10px;
}
</style>
